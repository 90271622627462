module.exports = {
  siteTitle: 'Iceberg Insights - Limited Beta Preview', // <title>
  manifestName: 'Iceberg Insights - Limited Beta Preview',
  manifestShortName: 'Iceberg', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/iceberg_icon.svg',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Iceberg Insights',
  subHeading: 'Ad-free high quality news.',

  // // social
  // socialLinks: [
  //   {
  //     icon: 'fa-github',
  //     name: 'Github',
  //     url: 'https://github.com/anubhavsrivastava',
  //   },
  //   {
  //     icon: 'fa-linkedin-in',
  //     name: 'Linkedin',
  //     url: 'https://linkedin.com/in/theanubhav/',
  //   },
  //   {
  //     icon: 'fa-twitter',
  //     name: 'Twitter',
  //     url: 'https://twitter.com/onlyanubhav',
  //   },
  //   {
  //     icon: 'fa-facebook-f',
  //     name: 'Facebook',
  //     url: 'https://facebook.com/theanubhav',
  //   },
  // ],
  // email: 'anubhav.srivastava00@gmail.com',
  // phone: '000-00000',
  // address: '305 Del Sol, Sunnyvale, CA',
};
