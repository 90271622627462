import React from 'react';

export default function Subscribe() {
  return (
    <section id="email-cta" className="email-cta">
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-lg-8 mx-auto text-center">
            <form method="post" 
              action="https://getform.io/f/21905761-2908-4ade-86ef-9326f1d10a21" 
              className="form-inline d-flex">
              <input
                type="email"
                name="email"
                className="form-control flex-fill mr-0 mr-sm-2 mb-3 mb-sm-0"
                id="inputEmail"
                placeholder="Type your best e-mail (and check your spam folder)"
              />
              <button type="submit" className="btn btn-primary mx-auto">
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
